import React from "react"

const IndexPage = () => (
    <div className="container is-fluid pt-4">
        <h1 className="title is-1">Loading app...</h1>
        <p className="subtitle is-4">This could take up to 24 hours on a fast computer. While you're waiting, go and get a cup of tea, watch Euphoria on HBO, or do something else. Up to you.</p>
        <progress class="progress is-large is-info" max="100"></progress>
    </div>
)

export default IndexPage
